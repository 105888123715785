import React from "react";
import blueBackgroundOptiverlogo from "../img/bluebackgroundoptiverlogo.png";
import { OptiverHeader, OptiverHeaderContent, OptiverHeaderTitle } from "../styles";

export const HeaderComponent = () => <OptiverHeader>
    <OptiverHeaderContent>
        <OptiverHeaderTitle>
            Optiver Trading Game
        </OptiverHeaderTitle>
        <img src={blueBackgroundOptiverlogo} width={108} height={26} align='right' alt='Optiver logo'>
        </img>
    </OptiverHeaderContent>
</OptiverHeader>;