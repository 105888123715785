import React, { useEffect, useState } from "react";
import { motion } from 'framer-motion';
import { Notosansjplight, Notosansjpblack } from "./styles";
import axios from "axios";
import { GameContainerComponent } from "./components/GameContainerComponent";

const buttonStyles = {
    background: "#FF3300",
    borderRadius: 60,
    width: 210,
    height: 55,
    color: "#ffffff",
    outline: "none",
    border: "none",
    cursor: "pointer",
}


const Admin = () => {

    const [gamesList, setGamesList] = useState([]);
    const [isGameUpdated, setIsGameUpdated] = useState(false);

    useEffect(() => {
        axios.get('http://localhost:5000/getGames/').then(
            res => {
                setGamesList(res.data.result);
            }
        ).catch(error => {
            console.log(error);
        });
        setIsGameUpdated(false);
    }, [isGameUpdated, setIsGameUpdated]);


    const createNewGame = (gameName) => {
        axios.post('http://localhost:5000/creategame/', {
            params: { gameName }
        }).then(
            res => {
                setIsGameUpdated(true);
            }
        ).catch(error => {
            console.log(error)
        })

    }


    return (
        <GameContainerComponent>
            <Notosansjpblack >
                Use this page to generate gameIDs
            </Notosansjpblack>
            {gamesList.length > 0 && gamesList.map((game) => (<div key={game._id}>{game.gameName}: {game._id}</div>))}
            <motion.button onClick={() => createNewGame(`game name ${gamesList.length + 1}`)} style={buttonStyles} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                <Notosansjplight style={{ fontSize: 18 }} >Generate Game</Notosansjplight>
            </motion.button>
        </GameContainerComponent>)

};

export default Admin;