import React, { useEffect, useState } from "react";
import { motion } from 'framer-motion';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Notosansjplight, Notosansjpblack } from "./styles";
import { GameContainerComponent } from "./components/GameContainerComponent";
import axios from 'axios';


const buttonStyles = {
    background: "#FF3300",
    borderRadius: 60,
    width: 210,
    height: 55,
    color: "#ffffff",
    outline: "none",
    border: "none",
    cursor: "pointer",
}
const textAnimationContainer = {
    hidden: { opacity: 0, paddingBottom: 0 },
    visible: { opacity: 1, paddingBottom: 20 }
}

const IntroPage = () => {

    let navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [hasRequiredParams, setHasRequiredParams] = useState(false);
    const [curInstance, setCurInstance] = useState(0);
    const [gameId] = useState(searchParams.get('gameId'));
    const [userName] = useState(searchParams.get('userName'));
    const [userId] = useState(searchParams.get('userId'));

    useEffect(() => {

        if (gameId && userName && userId) {
            axios.get(`http://localhost:5000/getGameById?gameId=${gameId}`).then(
                res => {
                    setHasRequiredParams(true);
                },
                err => {
                    console.log(err);
                }
            )

        }

        //to clear the querystring
        setSearchParams('');

        axios.get('http://localhost:5000/getRandomInstance/').then(
            res => {
                setCurInstance(res.data.randomNumberInstance)
            }
        ).catch(error => {
            console.log(error);
        })

    }, [setSearchParams, gameId, userName, userId]);

    const startGame = () => {
        axios.post('http://localhost:5000/register/', {
            params: {
                userName, gameId, userId
            }
        }).then(
            res => {
                let mydata = res.data
                const insertedId = mydata.insertedId
                let path = '/trading';
                navigate(path, { state: { insertedId: insertedId, playerName: userName, curInstance: curInstance, gameId: gameId } });

            }
        ).catch(error => {
            console.log(error)
        })

    }

    return (
        <GameContainerComponent>

            {hasRequiredParams ? <>

                <motion.div variants={textAnimationContainer} initial="hidden" animate="visible"
                    transition={{ duration: 1.5 }}
                    style={{ color: '#1C3255', alignItems: 'center', marginBottom: 20 }}>
                    <motion.span style={{ fontSize: 40, textAlign: 'center' }}>
                        <span className="notosansjpblack"  >
                            Welcome to the Optiver Trading Game!
                        </span>
                    </motion.span>
                </motion.div>

                <motion.div variants={textAnimationContainer} initial="hidden" animate="visible"
                    transition={{ duration: 1.5 }}
                    style={{ maxWidth: '63%', marginBottom: 80 }}>
                    <motion.span style={{ color: '#1C3255', fontSize: 24, textAlign: 'center', lineHeight: '32px' }}>
                        <Notosansjpblack>
                            Test your skills and compete against your friends on our trading floor simulator - let's see if you have what it takes to be an Optiver trader!
                        </Notosansjpblack>
                    </motion.span>
                </motion.div>

                <motion.div variants={textAnimationContainer} initial="hidden" animate="visible"
                    transition={{ duration: 1 }}
                    style={{ alignContent: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center', }}>
                    <motion.button onClick={startGame} style={buttonStyles} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
                        <Notosansjplight style={{ fontSize: 18 }} >Start</Notosansjplight>
                    </motion.button>
                </motion.div>
            </> :

                <div>Game data not provided</div>
            }


        </GameContainerComponent >
    )


}

export default IntroPage;