import styled from 'styled-components';

export const OptiverGamePage = styled.div` 
    height: 100vh;
    background-color: rgb(235, 245, 253);
    justify-content: center;
    align-items: center;
    display: flex;
`;

export const OptiverGameContainer = styled.div` 
    background-color: white;
    max-height: 825px;
    max-width: 1104px;
    border-radius: 2em;
    margin: -255px 10px 10px;
    display: flex;
    width: 100vw;
    height: 100vh;
    box-shadow: rgb(204, 204, 204) 2px 1px 50px 3px;
    flex-direction: column;
`;

export const OptiverHeader = styled.div` 
    width: 100%;
    background-color: rgb(28, 50, 85);
    border-top-left-radius: 2em;
    border-top-right-radius: 2em;
    height: 94px;
    display: flex;
    margin-top:0px;
`;

export const OptiverHeaderContent = styled.div` 
    flex: 1 1 0%;
    margin-right: 40px;
    margin-left: 40px;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    display: flex;
`;

export const OptiverHeaderTitle = styled.div` 
    flex: 1 1 0%;
    color: white;
    font-family: "Noto Sans JP", sans-serif;
    font-weight: 950;
    letter-spacing: -0.5px;
    font-size: 17px;
    padding-right: 10px;
    align-self: center;
`;

export const OptiverContentContainer = styled.div` 
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;



export const Thp1 = styled.div`
    align-content: center;
    background-color: #ffffff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 3em;

   
`

export const Bluebackgroundoptiverlogo = styled.div`
    align-content: right;
    background-color: #ffffff;
    color: #000;
    flex-direction: column;
    justify-content: right;
`

export const Decoration12 = styled.div`
    align-content: right;
    background-color: #ffffff;
    color: #000;
    flex-direction: column;
    justify-content: right;
`

export const Paragraph4 = styled.div`
    align-content: center;
    background-color: #ffffff;
    color: #1C3255;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 10em;

  
`

export const Paragraph3 = styled.div`
    align-content: center;
    background-color: #ffffff;
    color: #1C3255;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 10em;

  
`

export const Paragraph2 = styled.div`
    align-content: center;
    background-color: #ffffff;
    color: #1C3255;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 7em;   

   

`

export const ParagraphContainer = styled.div`
    align-content: center;
    background-color: #ffffff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 50em;
    flex-wrap:wrap;
    

`

export const Notosansjpblack = styled.div`
    font-family: 'Noto Sans JP', 
    sans-serif;
    font-weight: 950;
  `

export const Notosansjplight = styled.div`
    font-family: 'Noto Sans JP',sans-serif;
    font-weight: 300;
  `
export const Spp1 = styled.div`
    align-content: center;
    background-color: #ffffff;
    color: #1C3255;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 3em;
    
    span {
        text-align: left;
        padding-left: 30px;
        font-size: 17px;
      }
`

export const Ptraspaninvisibleintegertext = styled.div`
    opacity: 0;
    background-color: #ffffff;
    color: black;
    text-align: center;
    font-size: 20px;
    font-weight: 900;
`

export const Ptraptimer = styled.div`
    width: auto;
    align-content: right;
    background-color: #EDC3BE;
    color: #ff3300;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: x-large;
    min-height: 2em;
`

export const Ptra = styled.div`
    align-content: center;
    background-color: #ffffff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 50em;

    span {
        text-align: center;
    }
`
export const Ptra1 = styled.div`
    align-content: center;
    background-color: #ffffff;
    color: #1C3255;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 3em;

    span {
        text-align: left;
        padding-left: 30px;
        font-size: 17px;
    }
`

export const Ptra2 = styled.div`
    align-content: center;
    background-color: #ffffff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 20em;

    span {
        text-align: center;
    }
`

export const Ptra3 = styled.div`
    align-content: center;
    background-color: #ffffff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
    }
`

export const Ptra4 = styled.div`
    align-content: center;
    background-color: #fff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 5em;

    span {
        text-align: center;
        font-size: 20px;
        font-weight: bold;
    
    }
`

export const Ptra5 = styled.div`
    align-content: center;
    background-color: #fff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: x-large;

    span {
        text-align: center;
        font-weight: 900;
    }
`

export const Numberinbox = styled.div`
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 900;
    color:#1C3255;
`

export const Numberinpositionbox = styled.div`
    font-family:'Noto Sans JP', sans-serif ;
    font-weight: 900;
    color:#1C3255;
`

export const Lasttutorial = styled.div`
    align-content: center;
    background-color: #ffffff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 30em;
    flex-wrap:wrap;

    span {
        text-align: center;
        font-family: 'Noto Sans JP Black';
    }
`

export const Recordpartsell = styled.div`
    align-content: center;
    background-color: #ffffff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 3em;
    border: 1px solid grey;
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-width: 1px;
    border-bottom-width: 0px;
`

export const Recordpartbuy = styled.div`
    align-content: center;
    background-color: #ffffff;
    color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 3em;
    border: 1px solid grey;
    border-left-width: 0px;
    border-right-width: 0px;
    border-top-width: 1px;
    border-bottom-width: 0px;
`;