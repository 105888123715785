import React from "react";
import { OptiverGameContainer, OptiverGamePage, OptiverContentContainer } from "../styles";
import { HeaderComponent } from "./HeaderComponent";
import decoration12 from '../img/decoration12.png';


export const GameContainerComponent = ({ children }) => <OptiverGamePage>
    <OptiverGameContainer>
        <HeaderComponent />

        <OptiverContentContainer>
            {children}
        </OptiverContentContainer>
        <img src={decoration12} width={273} height={153} align='right' style={{ alignSelf: 'flex-end', marginTop: -153 }} alt='background decoration'>
        </img>
    </OptiverGameContainer>
</OptiverGamePage>
    ;