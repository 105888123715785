
import React, { useEffect, useState } from 'react';
import { Container, Grid, Segment, Divider, Checkbox } from "semantic-ui-react";
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Form, Dropdown } from 'semantic-ui-react'
import decoration9 from "./img/decoration9.png";
import decoration10 from "./img/decoration10.png";
import CryptoJS from 'crypto-js';
import cryptoRandomString from 'crypto-random-string';
import blueBackgroundOptiverlogo from "./img/bluebackgroundoptiverlogo.png";
import Joi from 'joi';
import swal from 'sweetalert';
import { Thp1 } from "./styles";



const spbuttonStyles = {
  background: "#FF3300",
  borderRadius: 60,
  width: 200,
  height: 50,
  marginTop: 50,
  color: "#ffffff",
  outline: "none",
  border: "none",
  cursor: "pointer",
}

const uniOptions = [
  { key: 'Australian National University', value: 'Australian National University', text: 'Australian National University' },
  { key: 'Monash University', value: 'Monash University', text: 'Monash University' },
  { key: 'University of New South Wales', value: 'University of New South Wales', text: 'University of New South Wales' },
  { key: 'University of Sydney', value: 'University of Sydney', text: 'University of Sydney' },
  { key: 'University of Melbourne', value: 'University of Melbourne', text: 'University of Melbourne' },
  { key: 'University of Queensland', value: 'University of Queensland', text: 'University of Queensland ' },
  { key: 'University of Auckland', value: 'University of Auckland', text: 'University of Auckland' },
  { key: 'University of Western Australia', value: 'University of Western Australia', text: 'University of Western Australia' },
  { key: 'University of Canterbury', value: 'University of Canterbury', text: 'University of Canterbury' },
  { key: 'University of Adelaide', value: 'University of Adelaide', text: 'University of Adelaide' },
  { key: 'University of Wellington', value: 'University of Wellington', text: 'University of Wellington' },
  { key: 'Bond University', value: 'Bond University', text: 'Bond University' },
  { key: 'Central Queensland University', value: 'Central Queensland University', text: 'Central Queensland University' },
  { key: 'Curtin University', value: 'Curtin University', text: 'Curtin University' },
  { key: 'Griffith University', value: 'Griffith University', text: 'Griffith University' },
  { key: 'La Trobe University', value: 'La Trobe University', text: 'La Trobe University' },
  { key: 'Macquarie University', value: 'Macquarie University', text: 'Macquarie University' },
  { key: 'Queensland University of Technology', value: 'Queensland University of Technology', text: 'Queensland University of Technology' },
  { key: 'RMIT University', value: 'RMIT University', text: 'RMIT University' },
  { key: 'Swinburne University of Technology', value: 'Swinburne University of Technology', text: 'Swinburne University of Technology' },
  { key: 'University of Technology Sydney', value: 'University of Technology Sydney', text: 'University of Technology Sydney' },
  { key: 'University of Newcastle', value: 'University of Newcastle', text: 'University of Newcastle' },
  { key: 'University of South Australia', value: 'University of South Australia', text: 'University of South Australia' },
  { key: 'University of Tasmania', value: 'University of Tasmania', text: 'University of Tasmania' },
  { key: 'Western Sydney University', value: 'Western Sydney University', text: 'Western Sydney University' },
  { key: 'University of Wollongong', value: 'University of Wollongong', text: 'University of Wollongong' },
  { key: 'Other', value: 'Other', text: 'Other' },


]

const fieldOptions = [
  { key: 'Chemical Engineering', value: 'Chemical Engineering', text: 'Chemical Engineering' },
  { key: 'Mechanical Engineering', value: 'Mechanical Engineering', text: 'Mechanical Engineering' },
  { key: 'Mechatronic Engineering', value: 'Mechatronic Engineering', text: 'Mechatronic Engineering' },
  { key: 'Aerospace Engineering', value: 'Aerospace Engineering', text: 'Aerospace Engineering' },
  { key: 'Electrical Engineering', value: 'Electrical Engineering', text: 'Electrical Engineering' },
  { key: 'Civil Engineering', value: 'Civil Engineering', text: ' Civil Engineering' },
  { key: 'Software Engineering', value: 'Software Engineering', text: ' Software Engineering' },
  { key: 'Mathematical Engineering', value: 'Mathematical Engineering', text: ' Mathematical Engineering' },
  { key: 'Computer Science', value: 'Computer Science', text: 'Computer Science' },
  { key: 'Computer Science & Engineering', value: 'Computer Science & Engineering', text: 'Computer Science & Engineering' },
  { key: 'Computational Science', value: 'Computational Science', text: 'Computational Science' },
  { key: 'Machine Learning', value: 'Machine Learning', text: 'Machine Learning' },
  { key: 'Physics', value: 'Physics', text: 'Physics' },
  { key: 'Applied/Theoretical Physics', value: 'Applied/Theoretical Physics', text: 'Applied/Theoretical Physics' },
  { key: 'Data Science', value: 'Data Science', text: 'Data Science' },
  { key: 'Business Statistics', value: 'Business Statistics', text: 'Business Statistics' },
  { key: 'Computational Finance', value: 'Computational Finance', text: 'Computational Finance' },
  { key: 'Mathematics & Computer Science', value: 'Mathematics & Computer Science', text: 'Mathematics & Computer Science' },
  { key: 'Quantitative Finance', value: 'Quantitative Finance', text: 'Quantitative Finance' },
  { key: 'Financial Engineering', value: 'Financial Engineering', text: 'Financial Engineering' },
  { key: 'Econometrics', value: 'Econometrics', text: 'Econometrics' },
  { key: 'Mathematics', value: 'Mathematics', text: 'Mathematics' },
  { key: 'Applied Mathematics', value: 'Applied Mathematics', text: 'Applied Mathematics' },
  { key: 'Statistics', value: 'Statistics', text: 'Statistics' },
  { key: 'Other', value: 'Other', text: 'Other' },


]

const graduateyearOptions = [
  { key: '2023', value: '2023', text: '2023 ' },
  { key: '2024', value: '2024', text: '2024 ' },
  { key: '2025', value: '2025', text: '2025 ' },
  { key: '2026', value: '2026', text: '2026 ' },
  { key: '2027', value: '2027', text: '2027 ' },
  { key: '2028', value: '2028', text: '2028 ' },
  { key: '2029', value: '2029', text: '2029 ' },
  { key: 'Graduated', value: 'Graduated', text: 'Graduated ' },
]

const genderOptions = [
  { key: 'She/Her', value: 'She/Her', text: 'She/Her ' },
  { key: 'He/Him', value: 'He/Him', text: 'He/Him' },
  { key: 'They/Them', value: 'They/Them', text: 'They/Them' },
  { key: 'Confidential', value: 'Confidential', text: 'Confidential' },
]


const SignUp = () => {

  const [playerName, setPlayerName] = useState("");
  const [playerLastName, setPlayerLastName] = useState("");
  const [email, setEmail] = useState("");
  const [currentUni, setCurrentUni] = useState(uniOptions);
  const [currentField, setCurrentField] = useState(fieldOptions);
  const [currentGraduateyear, setCurrentGraduateyear] = useState(graduateyearOptions);
  const [currentGender, setCurrentGender] = useState(genderOptions);

  const [checkboxClicked, setCheckboxClicked] = useState(false);
  //determine which instance the player will play with
  const [curInstance, setCurInstance] = useState(0);
  // const [isDisabled, setIsDisabled] = useState(false);


  const handleNameChange = e => {
    setPlayerName(e.target.value);
  }

  const handleLastNameChange = e => {
    setPlayerLastName(e.target.value);
  }

  const handleEmailChange = e => {
    setEmail(e.target.value);
  }


  const handleUniAddition = (e, { value }) => {
    this.setState((prevState) => ({
      uniOptions: [{ text: value, value }, ...prevState.uniOptions],
    }))

  }

  const handleUniChange = (e, { value }) => {
    if (value != undefined && value != "") {
      setCurrentUni(value)
    } else {
      setCurrentUni(e.target.value);
    }
  }


  const handleFieldAddition = (e, { value }) => {
    this.setState((prevState) => ({
      fieldOptions: [{ text: value, value }, ...prevState.fieldOptions],
    }))
  }
  const handleFieldChange = (e, { value }) => {
    if (value != undefined && value != "") {
      setCurrentField(value)
    } else {
      setCurrentField(e.target.value);
    }
  }


  const handleGraduateyearAddition = (e, { value }) => {
    this.setState((prevState) => ({
      graduateyearOptions: [{ text: value, value }, ...prevState.graduateyearOptions],
    }))
  }
  const handleGraduateyearChange = (e, { value }) => {
    if (value != undefined && value != "") {
      setCurrentGraduateyear(value)
    } else {
      setCurrentGraduateyear(e.target.value);
    }
  }
  const handleGenderAddition = (e, { value }) => {
    this.setState((prevState) => ({
      genderOptions: [{ text: value, value }, ...prevState.genderOptions],
    }))
  }
  const handleGenderChange = (e, { value }) => {
    if (value != undefined && value != "") {
      setCurrentGender(value)
    } else {
      setCurrentGender(e.target.value);
    }
  }

  const handleCheckboxClick = () => {
    setCheckboxClicked(!checkboxClicked);
  }

  useEffect(() => {
    axios.get('http://localhost:5000/getRandomInstance/').then(
      res => {
        setCurInstance(res.data.randomNumberInstance)
      }
    ).catch(error => {
      // window.location.reload();
      //woo
    })
  }, [])


  let navigate = useNavigate();

  const handleClick = () => {
    routeChange();
  }
  const routeChange = () => {
    // console.log(playerName)
    // console.log(currentUni)s
    if (playerName != undefined && playerName != ""
      && playerLastName != undefined && playerLastName != ""
      && email != undefined && email != ""
      && currentUni != undefined && currentUni != "" && Array.isArray(currentUni) == false
      && currentField != undefined && currentField != "" && Array.isArray(currentField) == false
      && currentGraduateyear != undefined && currentGraduateyear != "" && Array.isArray(currentGraduateyear) == false
      && currentGender != undefined && currentGender != "" && Array.isArray(currentGender) == false
      && checkboxClicked) {

      const key = cryptoRandomString({ length: 32 }).toString();

      const playerNameCiphertext = CryptoJS.AES.encrypt(playerName, key).toString();
      const playerLastNameCiphertext = CryptoJS.AES.encrypt(playerLastName, key).toString();
      const emailCiphertext = CryptoJS.AES.encrypt(email, key).toString();
      const currentUniCiphertext = CryptoJS.AES.encrypt(currentUni, key).toString();
      const currentFieldCiphertext = CryptoJS.AES.encrypt(currentField, key).toString();
      const currentGraduateyearCiphertext = CryptoJS.AES.encrypt(currentGraduateyear, key).toString();
      const currentGenderCiphertext = CryptoJS.AES.encrypt(currentGender, key).toString();


      const isFormValid = () => {
        const firstNameValidation = Joi.string().regex(/^[a-zA-Z\s\,\.\&\-]+$/).min(1).max(30).required();
        const lastNameValidation = Joi.string().regex(/^[a-zA-Z0-9\s\,\.\&\-]+$/).min(1).max(30).required();
        const emailValidation = Joi.string().regex(/^[\w-.]+@([\w-]+\.)+[\w-]{2,}$/).required();
        // const uniValidation = Joi.string().regex(/^[a-zA-Z\s\,\.\&\-]+$/).min(1).max(50).required();
        const graduateYearValidation = Joi.string().regex(/^(Graduated|\d+)$/).min(2).max(30).required();
        const fieldValidation = Joi.string().regex(/^[a-zA-Z\s\,\.\&\-]+$/).min(1).max(30).required();

        const firstNameError = firstNameValidation.validate(playerName).error;
        const lastNameError = lastNameValidation.validate(playerLastName).error;
        const emailError = emailValidation.validate(email).error;
        // const uniError = uniValidation.validate(currentUni).error;
        const graduateYearError = graduateYearValidation.validate(currentGraduateyear).error;
        const fieldError = fieldValidation.validate(currentField).error;

        // if(!firstNameError &&
        //   !lastNameError &&
        //   !emailError &&
        //   !uniError &&
        //   !graduateYearError &&
        //   !fieldError){
        //     return 1;
        // }
        // else if(firstNameError){
        //   return 2;
        // }else if(lastNameError){
        //   return 3;
        // }
        // else if(emailError){
        //   return 4;
        // }else if(uniError){
        //   return 5;
        // }else if(graduateYearError){
        //   return 6;
        // }else if(fieldError){
        //   return 7;
        // }else {
        //   return 8;
        // }
        if (!firstNameError &&
          !lastNameError &&
          !emailError &&

          !graduateYearError &&
          !fieldError) {
          return 1;
        }
        else if (firstNameError) {
          return 2;
        } else if (lastNameError) {
          return 3;
        }
        else if (emailError) {
          return 4;
        } else if (graduateYearError) {
          return 6;
        } else if (fieldError) {
          return 7;
        } else {
          return 8;
        }
      };

      if (isFormValid() == 1) {
        // submit form data
        axios.post('http://localhost:5000/register/', {
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0'
          },
          params: {
            playerNameCiphertext, playerLastNameCiphertext, emailCiphertext,
            currentUniCiphertext, currentFieldCiphertext, currentGraduateyearCiphertext, currentGenderCiphertext, key
          }
        }).then(
          res => {
            let mydata = res.data
            const insertedId = mydata.insertedId
            let path = '/trading';
            navigate(path, { state: { insertedId: insertedId, playerName: playerName, curInstance: curInstance } });

          }
        ).catch(error => {
          console.log(error)
        })

      }
      else if (isFormValid() == 2) {

        swal('Please provide valid first name, only symbols below are allowed: spaces "\," "\." "\&","-"  ');
      }
      else if (isFormValid() == 3) {
        swal('Please provide valid last name, only symbols below are allowed: spaces "\," "\." "\&","-" ');
      }
      else if (isFormValid() == 4) {
        swal('Please provide valid email, make sure it is something like abcd@xxxx.yyy');
      }
      else if (isFormValid() == 5) {
        swal('Please provide valid university name, only symbols below are allowed: spaces "\," "\." "\&","-" ');
      }
      else if (isFormValid() == 6) {
        swal('Please provide valid graduate year, only digits are allowed, or you may select "Graduated" ');
      }
      else if (isFormValid() == 7) {
        swal('Please provide valid study field, only symbols below are allowed: spaces "\," "\." "\&","-" ');
      }
      else {
        swal('Please check all fields since more than one fields are invalid, only symbols below are allowed: spaces "\," "\." "\&","-", and please make sure the email is valid ');
      }

    }
  }

  return (
    <>
      <Container style={{
        paddingTop: 0.1 * window.innerHeight, paddingBottom: 0.1 * window.innerHeight,
        paddingLeft: 0.01 * window.innerWidth, paddingRight: 0.01 * window.innerWidth
      }}>
        <style>
          {`
                html,body{
                  background-color: rgb(235,245,253);
                }
                spp {
                    align-content: center;
                    background-color: #ffffff;
                    color: #000;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    min-height: 50em;
                }
                spp > span {
                    opacity: 0.4;
                    text-align: center;
                }

                spp1 {
                    align-content: center;
                    background-color: #ffffff;
                    color: #1C3255;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    min-height: 3em;
                }
                
                spp1 > span1 {
                    text-align: left;
                    padding-left: 30px;
                    font-size: 17px;
                  }
                  spp1 > span2 {
                    opacity: 0.8;
                    text-align: right;
                    padding-right: 30px;
                    font-size: 20px;
                }
                
                buttonSpan{
                    font-size:22px;
                }

                textSpan {
                  text-align: center;
                  font-size: 18px;
                  font-weight: 900;
                  color:#1C3255;
                }
                optiverlogo{
                  align-content: right;
                  background-color: #ffffff;
                  color: #000;
                  flex-direction: column;
                  justify-content: right;
                }
                decoration1{
                  align-content: right;
                  background-color: #ffffff;
                  color: #000;
                  flex-direction: column;
                  justify-content: right;
                }
                decoration2{
                  align-content: right;
                  background-color: #ffffff;
                  color: #000;
                  flex-direction: column;
                  justify-content: right;
                }
            `}
        </style>

        <Grid columns='equal'>
          <Segment class="segmentStyle" style={{
            borderRadius: "2em",
            borderColor: 'white',
            borderWidth: '1px',
            boxShadow: '2px 1px 50px 3px #cccccc '
          }}>
            <Grid.Row columns='equal'>
              <Grid.Column >

                <Grid style={{
                  width: '102.9%', marginLeft: -15.5, marginTop: -15, backgroundColor: '#1c3255', borderTopLeftRadius: "2em",
                  borderTopRightRadius: "2em"
                }} >
                  <Grid.Row columns={2} style={{ marginTop: 14 }} >
                    <Grid.Column floated='left' style={{ paddingRight: 0, paddingBottom: 10 }}>
                      <Thp1 style={{ color: '#ffffff', backgroundColor: '#1c3255' }}>
                        <span class="notosansjpblack"  >Optiver Trading Game</span>
                      </Thp1>
                    </Grid.Column>
                    <Grid.Column floated='right' style={{ paddingLeft: 0 }}>
                      <bluebackgroundoptiverlogo>
                        <img src={blueBackgroundOptiverlogo} width="20%" height="50%" align='right' style={{ marginTop: '10px', marginRight: '28px' }}>
                        </img>
                      </bluebackgroundoptiverlogo>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
                <Divider style={{ marginLeft: -14, width: '102.55%' }} />

                <Grid>
                  <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                      <spp>
                        <Grid style={{ marginTop: 0 }}>
                          <Grid.Row>

                          </Grid.Row>
                          <Grid.Row style={{ paddingTop: 0, paddingBottom: -14 }}>
                            <Grid.Column >
                              <Grid relaxed>
                                <Grid.Row>
                                  <Grid.Column width={2}>
                                  </Grid.Column>
                                  <Grid.Column width={12}>
                                    <Grid relaxed>
                                      <Grid.Row>
                                        <Grid.Column style={{ textAlign: 'center', marginTop: 120 }}>
                                          <textSpan className="notosansjpblack"  >
                                            Enter your details to begin!
                                          </textSpan>
                                        </Grid.Column>
                                      </Grid.Row>
                                    </Grid>
                                  </Grid.Column>

                                  <Grid.Column width={2}>
                                  </Grid.Column>
                                </Grid.Row>
                              </Grid>

                              <Form>

                                <Grid relaxed >
                                  <Grid.Row style={{ marginTop: 14 }} >
                                    <Grid.Column width={2}>
                                    </Grid.Column>
                                    <Grid.Column width={6} style={{ paddingRight: 7 }}>
                                      <Form.Field>
                                        <input placeholder='*First Name' class="notosansjpnormal" value={playerName} onChange={handleNameChange} />
                                      </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column width={6} style={{ paddingLeft: 7 }}>
                                      <Form.Field>
                                        <input placeholder='*Last Name' class="notosansjpnormal" value={playerLastName} onChange={handleLastNameChange} />
                                      </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                    </Grid.Column>
                                  </Grid.Row>
                                  <Grid.Row style={{ marginTop: -21 }}>
                                    <Grid.Column width={2}>
                                    </Grid.Column>
                                    <Grid.Column width={12}>
                                      <Form.Field>
                                        <input placeholder='*Email' class="notosansjpnormal" value={email} onChange={handleEmailChange} />
                                      </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                    </Grid.Column>
                                  </Grid.Row>
                                  <Grid.Row style={{ marginTop: -21 }}>
                                    <Grid.Column width={2}>
                                    </Grid.Column>
                                    <Grid.Column width={12}>
                                      <Form.Field>
                                        {/* <Dropdown.Header icon='tags' content='Filter by tag' />  */}

                                        <Dropdown
                                          id="large-picker"
                                          placeholder='*University Name'
                                          options={uniOptions}
                                          fluid
                                          search
                                          selection
                                          // closeOnChange
                                          allowAdditions
                                          additionLabel=' '
                                          // defaultValue={currentUni}
                                          onAddItem={handleUniAddition}
                                          onChange={handleUniChange} />
                                      </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                    </Grid.Column>
                                  </Grid.Row>

                                  <Grid.Row style={{ marginTop: -21 }}>
                                    <Grid.Column width={2}>
                                    </Grid.Column>
                                    <Grid.Column width={12}>
                                      <Form.Field>
                                        <Dropdown
                                          id="large-picker"
                                          placeholder='*Field of Study'
                                          fluid
                                          search
                                          selection
                                          options={fieldOptions}
                                          allowAdditions
                                          closeOnChange
                                          additionLabel=' '
                                          // defaultValue={currentField}
                                          onAddItem={handleFieldAddition}
                                          onChange={handleFieldChange} />
                                      </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                    </Grid.Column>

                                  </Grid.Row>

                                  <Grid.Row style={{ marginTop: -21 }}>
                                    <Grid.Column width={2}>
                                    </Grid.Column>
                                    <Grid.Column width={12}>
                                      <Form.Field>
                                        <Dropdown
                                          id="large-picker"
                                          placeholder='*Graduate Year'
                                          fluid
                                          search
                                          selection
                                          options={graduateyearOptions}
                                          allowAdditions
                                          closeOnChange
                                          additionLabel=' '
                                          // defaultValue={currentGraduateyear}
                                          onAddItem={handleGraduateyearAddition}
                                          onChange={handleGraduateyearChange} />
                                      </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                    </Grid.Column>

                                  </Grid.Row>

                                  <Grid.Row style={{ marginTop: -21 }}>
                                    <Grid.Column width={2}>
                                    </Grid.Column>
                                    <Grid.Column width={12}>
                                      <Form.Field>
                                        <Dropdown
                                          id="large-picker"
                                          placeholder='*Pronouns'
                                          fluid
                                          search
                                          selection
                                          options={genderOptions}
                                          allowAdditions
                                          closeOnChange
                                          additionLabel=' '
                                          // defaultValue={currentGender}
                                          onAddItem={handleGenderAddition}
                                          onChange={handleGenderChange} />
                                      </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                    </Grid.Column>

                                  </Grid.Row>

                                  <Grid.Row style={{ marginTop: -21 }}>
                                    <Grid.Column width={2}>
                                    </Grid.Column>
                                    <Grid.Column width={12}>
                                      <Grid relaxed>
                                        <Grid.Row>
                                          {/* <Grid.Column width={4}>
                                    </Grid.Column> */}
                                          <Grid.Column width={16}>
                                            <Form.Field>

                                              <label >
                                                <Checkbox onChange={handleCheckboxClick} className="notosansjplightforprivacy" color='#1c3255'
                                                  label=
                                                  {
                                                    <label>
                                                      <Segment style={{ border: 0, borderWidth: 0, boxShadow: 'none', margin: 0, paddingRight: 0, paddingTop: 0 }}>
                                                        <span class="notosansjplightforprivacy" style={{ color: '#1c3255', fontSize: 12 }}>
                                                          Optiver takes the protection of your personal information seriously and is committed to keeping your
                                                          personal information safe and confidential. For full details about how Optiver uses your personal
                                                          information in our recruitment process, please see our
                                                          <a href="https://optiver.com/privacypolicy/" style={{ marginLeft: 5, marginRight: 5 }}>
                                                            Privacy Policy.
                                                          </a>
                                                          I give permission to Optiver to
                                                          contact me by email about Optiver roles, company updates, recruitment activities, and events relevant
                                                          to my career. If you would prefer that we do not retain your personal information for this purpose please
                                                          let us know by emailing us at careers@optiver.com.au.
                                                        </span>
                                                      </Segment>


                                                    </label>
                                                  }

                                                />


                                              </label>

                                            </Form.Field>
                                          </Grid.Column>

                                        </Grid.Row>
                                      </Grid>
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                    </Grid.Column>

                                  </Grid.Row>

                                  <Grid.Row style={{ marginTop: -28 }}>
                                    <Grid.Column width={2}>
                                    </Grid.Column>
                                    <Grid.Column width={12}>
                                      <Grid relaxed>
                                        <Grid.Row >
                                          <Grid.Column style={{ textAlign: 'center' }}>
                                            <motion.button type="submit" onClick={handleClick} style={spbuttonStyles} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.5 }}
                                              transition={{ duration: 1 }} >
                                              <buttonSpan className="notosansjplight" style={{ fontSize: 18, fontWeight: 300 }}>Submit</buttonSpan>
                                            </motion.button>
                                          </Grid.Column>
                                        </Grid.Row>
                                      </Grid>
                                    </Grid.Column>
                                    <Grid.Column width={2}>
                                    </Grid.Column>
                                  </Grid.Row>

                                </Grid>
                              </Form>

                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </spp>
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <decoration10 >
                        <img src={decoration10} align='right' style={{ marginTop: -15, marginRight: -16 }} >
                        </img>
                      </decoration10>
                    </Grid.Column>

                  </Grid.Row>
                </Grid>

              </Grid.Column>

            </Grid.Row>
            <Grid.Row>
              <decoration9>
                <img src={decoration9} align='left' style={{ marginLeft: -14, marginBottom: -15 }} >
                </img>
              </decoration9>
            </Grid.Row>

          </Segment>

        </Grid>


      </Container>

    </>
  )


}



export default SignUp;
