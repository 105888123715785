import React, { useState, useEffect, useRef } from "react";
import { Container, Header, Divider, Grid } from "semantic-ui-react";
import axios from 'axios';
import optiverlogo from "./img/optiverlogo.svg";
import CryptoJS from 'crypto-js';

const Leaderboard = () => {
    const [records, setRecords] = useState([]);
    const [refreshFlag, setRefreshFlag] = useState(false);
    const refresh = useRef();
    let scoreRecord = records.map((x) => x);

    const [users, setUsers] = useState();

    useEffect(() => {
        getUsersInfo();
        setRefreshFlag(true);
    }, [])

    useEffect(() => {
        if (refreshFlag) {
            refresh.current = setInterval(() => getUsersInfo(), 30000)
        } else if (!refreshFlag) {
            clearInterval(refresh.current);
            //click the replay button to change refresh flag so that it 
            // clears the interval and redirect to the start page
        }
    }, [refreshFlag]);

    const getUsersInfo = () => {
        axios.get('http://localhost:5000/getLeaderboard/').then(
            res => {
                const encryptedData = res.data.encryptedData;
                const encryptedKey = res.data.encryptedKey;
                // the key is valid, use it to decrypt the data
                const key = JSON.parse(CryptoJS.AES.decrypt(encryptedKey, "thanksforplaying").toString(CryptoJS.enc.Utf8));
                //TODO need to investigate if this impacts new leaderboard page
                //const decryptedData = JSON.parse(CryptoJS.AES.decrypt(encryptedData, key.toString()).toString(CryptoJS.enc.Utf8));

                let mydata = res.data.encryptedData
                //remove records that without a result
                mydata = mydata.filter(function (item) {
                    return (item != undefined && item.result != undefined)
                })
                // format names 
                mydata.forEach(element => {
                    element.playerName = element.playerName.replace(/\n|\r|\s/g, '');
                });
                // sort the Array
                mydata.sort((a, b) => {
                    if (a == undefined || b == undefined
                        || a.playerName == undefined || b.playerName == undefined
                        || a.playerName == "" || b.playerName == ""
                        || a.result == undefined || b.result == undefined) return -1;
                    return a.result - b.result;
                });

                if (mydata.length > 10) {
                    setRecords(mydata.slice(-10))
                } else if (mydata.length >= 0 && mydata.length <= 10) {
                    setRecords(mydata)
                }
                //server test

                setUsers(mydata);
            }
        ).catch({
        })
    }

    const showLeaderboard = () => {
        console.log(records.length)
        let resultArray = [];
        if (users != undefined) {
            for (let i = 0; i < scoreRecord.length; i++) {
                if (scoreRecord.length > 0) {
                    let item = scoreRecord[scoreRecord.length - i - 1];
                    let mycolor;
                    if (i % 2 == 0) {
                        //white back

                    } else {
                        //light back
                        mycolor = '#EBF5FD'
                    }
                    resultArray.push(
                        <Grid.Row >
                            <Grid.Column width={2}>

                            </Grid.Column>
                            <Grid.Column width={12}>
                                <Grid.Row style={{ backgroundColor: mycolor }} >
                                    < Grid >
                                        <Grid.Column width={4} textAlign='center' className="notosansjpnormal" style={{ marginTop: 14, marginBottom: 14, fontSize: 28, fontWeight: 700, marginLeft: 28, color: '#1C3255' }}>
                                            {i > 8 ? i + 1 : "0" + (i + 1)}
                                        </Grid.Column>
                                        <Grid.Column width={8} textAlign='center' className="notosansjpnormal" style={{ marginTop: 14, marginBottom: 14, fontSize: 28, fontWeight: 700, color: '#1C3255' }}>
                                            {item.playerName}
                                        </Grid.Column>
                                        <Grid.Column width={3} textAlign='center' className="notosansjpnormal" style={{ marginTop: 14, marginBottom: 14, fontSize: 28, fontWeight: 700, marginRight: 40, color: '#1C3255' }}>
                                            {item.result}
                                        </Grid.Column>
                                    </Grid >
                                </Grid.Row>

                            </Grid.Column>
                            <Grid.Column width={2}>

                            </Grid.Column>
                        </Grid.Row>
                    )
                }

                else {
                    console.log("invalid leaderboard records length")
                }
            }
        }

        return (
            <p style={{ height: window.innerHeight - 345, justifyContent: 'start' }}>
                <Grid relaxed >
                    {resultArray}

                </Grid>
            </p>
        )
    }


    return (
        <Container style={{
            width: window.innerWidth
            // height:window.innerHeight
        }}>
            <style>
                {`
                    html,body{
                        background-color: rgb(255,255,255);
                    }
                    p {
                        align-content: center;
                        background-color: #ffffff;
                        color: #000;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                    p > span {
                        text-align: center;
                    }
                    p1 {
                        align-content: center;
                        background-color: #ffffff;
                        color: #000;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        min-height: 3em;s
                    }
                    
                    p1 > span1 {
                        text-align: center;
                        font-size: 50px;
                    }
                    scooptiverlogo {
                        align-content: center;
                        flex-direction: column;
                        justify-content: center;
                    }
                    spaninvisible{
                        opacity: 0;
                        background-color: #ffffff;
                        color: black;
                        text-align: center;
                        font-size: 20px;
                        font-weight: 900;
                    }
                    replaybuttontext{
                        font-family:'Noto Sans JP', sans-serif;
                        font-weight: 300;
                        font-size: 18px;
                    }
                    .ui.header{
                        font-family:'Noto Sans JP', sans-serif;
                    }
                    
                `}
            </style>

            <Grid columns='equal'>
                <Grid.Row style={{ paddingBottom: 0 }}>
                    <Grid.Column >

                        <Grid.Row>
                            <Header as='h1' textAlign='center'>

                            </Header>

                            <Grid relaxed>
                                <Grid.Row>

                                    <Grid.Column width={2}>

                                    </Grid.Column>
                                    <Grid.Column width={12}>
                                        <Grid style={{ marginBottom: -30 }}>
                                            <Grid.Column floated='left' width={8} >
                                                <span style={{ marginBottom: -20, fontWeight: 900, fontSize: 34, color: '#1c3255', fontFamily: "'Noto Sans JP', sans-serif" }}>
                                                    Trading Game Leaderboard
                                                </span>
                                            </Grid.Column >
                                            <Grid.Column floated='right'>
                                                <scooptiverlogo >
                                                    <img src={optiverlogo} width="250%" height="250%" align='right' style={{ marginTop: -20 }}>
                                                    </img>
                                                </scooptiverlogo>
                                            </Grid.Column >
                                        </Grid>

                                    </Grid.Column>
                                    <Grid.Column width={2}>


                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>


                            <Grid relaxed>
                                <Grid.Row>

                                    <Grid.Column width={2}>

                                    </Grid.Column>
                                    <Grid.Column width={12}>
                                        <Divider />
                                    </Grid.Column>
                                    <Grid.Column width={2}>

                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            <Grid relaxed style={{ marginTop: -20, marginBottom: -40 }}>
                                <Grid.Row>

                                    <Grid.Column width={3}>

                                    </Grid.Column>
                                    <Grid.Column width={11}>
                                        <Grid.Row >
                                            < Grid >
                                                <Grid.Column width={2} textAlign='center' style={{ marginLeft: 0, color: '#1C3255' }}>
                                                    <span className="notosansjpblack" style={{ fontSize: 28 }}>Rank</span>
                                                </Grid.Column>
                                                <Grid.Column width={10} textAlign='center' style={{ color: '#1C3255', paddingLeft: 100 }}>
                                                    <span className="notosansjpblack" style={{ fontSize: 28 }}>Name</span>
                                                </Grid.Column>
                                                <Grid.Column width={2} textAlign='center' style={{ paddingLeft: 90, color: '#1C3255' }}>
                                                    <span className="notosansjpblack" style={{ fontSize: 28 }}>Score</span>
                                                </Grid.Column>
                                            </Grid >

                                        </Grid.Row>
                                    </Grid.Column>
                                    <Grid.Column width={2}>

                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>

                            <Grid relaxed>
                                <Grid.Row>
                                    <Grid.Column width={2}>

                                    </Grid.Column>
                                    <Grid.Column width={12}>
                                        <Divider style={{ paddingBottom: 10 }} />
                                    </Grid.Column>
                                    <Grid.Column width={2}>

                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                            {
                                showLeaderboard()
                            }
                        </Grid.Row>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    )
}

export default Leaderboard;